<template>
  <div class="ui grid container-2" id="features">
    <div class="two column row stackable">
      <div class="column div-1">
        <div class="left">
          <h1>
            <span>Get the most out of your</span>
            TayoCash membership<span>!</span>
          </h1>
          <div class="ui items">
            <div class="item">
              <div class="image">
                <img class="mid-phone" src="@/assets/check.png">
              </div>
              <div class="content">
                <p>Use your TayoCash e-wallet as an <span class="vtxt-align">easy and cashless way to pay</span> for everyday essentials</p>
              </div>
            </div>
          </div>

          <div class="ui items">
            <div class="item">
              <div class="image">
                <img class="mid-phone" src="@/assets/check.png">
              </div>
              <div class="content">
                <p><span class="vtxt-align">Pay your bills</span> for utilities like electricity, water, and others without leaving your house</p>
              </div>
            </div>
          </div>

          <div class="ui items">
            <div class="item">
              <div class="image">
                <img class="mid-phone" src="@/assets/check.png">
              </div>
              <div class="content">
                <p><span class="vtxt-align">Buy prepaid load</span>, and even game credits, anytime directly from your TayoCash app</p>
              </div>
            </div>
          </div>

          <div class="ui items">
            <div class="item">
              <div class="image">
                <img class="mid-phone" src="@/assets/check.png">
              </div>
              <div class="content">
                <p><span class="vtxt-align">Transfer money real-time</span> to friends and family using your TayoCash e-wallet</p>
              </div>
            </div>
          </div>

          <div class="ui items">
            <div class="item">
              <div class="image">
                <img class="mid-phone" src="@/assets/check.png">
              </div>
              <div class="content">
                <p>Avail of upcoming <span class="vtxt-align">discounts and promos</span> exclusive to members of the TayoCash community</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="column div-2"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'content2'
}
</script>

<style scoped lang='scss'>
</style>
